// bootstrap
@import './bootstrap.scss';

html {
  // smooth scrolling
  scroll-behavior: smooth;

  // so we can fix our footer
  height: 100%;

  // border box sizing
  box-sizing: border-box;
}

body {
  // site-wide font
  font-family: 'Poppins', sans-serif;

  // for scroll spy
  position: relative;
  min-height: 100%;
}

// outdated browser warning
#outdated {
  // sit on top of everything
  z-index: 5001;

  // offset
  border-bottom: 2px solid black;

  // close
  .last {
    top: 0;
    right: 4px;
  }
}

// no outlines by default
:focus {
  outline: none;
}

// buttons
button {
  &:disabled {
    cursor: not-allowed;
  }
}

// clickable things
.clickable {
  cursor: pointer;
}

// main content
main {
  // don't overlap footer
  padding-bottom: 56px;

  // sections
  section {
    margin-bottom: 30px;
  }
}

// forms
form {
  $form-field-valid: $green;
  $form-field-error: $red;
  $border-size: 3px;

  label {
    @include font-size($font-size-lg);
    &::after {
      content: '*';
      vertical-align: super;
      color: transparent;
      @include font-size($font-size-sm);
    }
    &.required::after {
      color: $red;
    }
  }

  input,
  textarea {
    &.form-control {
      border-left: $border-size solid rgb(206, 212, 218);
    }

    &:invalid {
      // highlight invalid fields
      border-left: $border-size solid $form-field-error;
    }

    &:focus:invalid {
      // highlight invalid fields
      border-color: $form-field-error;
      box-shadow: 0 0 0 0.05rem $form-field-error;
    }

    &:valid[required] {
      // highlight valid fields
      border-left: $border-size solid $form-field-valid;
    }

    &:focus:valid {
      // highlight valid fields
      border-color: $form-field-valid;
      box-shadow: 0 0 0 0.05rem $form-field-valid;
    }
  }

  input[type='checkbox'],
  input[type='radio'] {
    &:focus:valid {
      // do not highlight fields
      border-color: transparent;
      box-shadow: 0 0 0 0;
    }
  }

  // character limit
  .limit {
    text-align: right;
    @include font-size($font-size-xs);
    color: $gray-600;
  }
}

// modals
.modal {
  .modal-header {
    @include font-size($font-size-xl);
    font-weight: $font-weight-light;
    text-align: right;
  }
}

// simulate a link
.link {
  cursor: pointer;
  color: $link-color;
  text-decoration: $link-decoration;
  background-color: transparent;

  @include hover() {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }
}

// an external link
.link-external {
  vertical-align: super;
  @include font-size($font-size-xs);
  &::before {
    content: ' ';
  }
}

// alerts
.alert {
  p:last-of-type {
    margin-bottom: 0;
  }
}

// loading
.loader {
  height: 30px;
  width: 250px;
  left: 0;
  right: 0;
  margin: auto;

  // can't interact with it
  cursor: not-allowed;

  .loader--dot {
    animation-name: loader;
    animation-timing-function: ease-in-out;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    height: 30px;
    width: 30px;
    border-radius: 100%;
    background-color: black;
    position: absolute;
    border: 2px solid white;

    &:first-child {
      background-color: $green;
      animation-delay: 0.5s;
    }
    &:nth-child(2) {
      background-color: $red;
      animation-delay: 0.4s;
    }
    &:nth-child(3) {
      background-color: $purple;
      animation-delay: 0.3s;
    }
    &:nth-child(4) {
      background-color: $yellow;
      animation-delay: 0.2s;
    }
    &:nth-child(5) {
      background-color: $orange;
      animation-delay: 0.1s;
    }
    &:nth-child(6) {
      background-color: $blue;
      animation-delay: 0s;
    }
  }

  @keyframes loader {
    15% {
      transform: translateX(0);
    }
    45% {
      transform: translateX(215px);
    }
    65% {
      transform: translateX(215px);
    }
    95% {
      transform: translateX(0);
    }
  }
}

// confirmation
#react-confirm-alert {
  .react-confirm-alert {
    border: 1px solid black;
    border-radius: 6px;
    padding: 15px;
    background: white;
    margin: 0 10px 0 10px;

    // on top of everything
    z-index: 2000;
  }

  .react-confirm-alert-overlay {
    // on top of most things
    z-index: 1900;

    // black background with opacity
    background-color: rgba(0, 0, 0, 0.5);
  }
}

// progress bar
.progress {
  height: 2px;
  border-radius: 0;
  .progress-bar {
    background-color: $secondary;
  }
}
