// bootstrap overrides
$primary: #fd7e14;
$secondary: #5daef2;
$font-size-base: 1.15rem;
$grid-gutter-width: 10px !default;
$label-margin-bottom: 0.1rem;

// new font sizes
$font-size-xs: $font-size-base * 0.625 !default;
$font-size-xl: $font-size-base * 1.35 !default;

// rfs
$enable-responsive-font-sizes: true !default;
$rfs-base-font-size: 0.7rem !default;

// custom variables
$site-min-width: 250px;
$content-min-width: $site-min-width + 25px;

// bootstrap
@import 'node_modules/bootstrap/scss/bootstrap';
